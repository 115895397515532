<template>
  <div class="invitation d-flex align-items-center justify-content-center">
    <div class="invite text-center">
      <!-- <h6>welcome</h6> -->
      <img src="../../assets/images/vue-logo.jpg" class="logoo" alt="logo" />
      <p class="invite-message">
        {{ $t("global_alerts.friend invitation") }}
        <span class="freind-name ">{{ freindName }} </span>
      </p>
      <div class="buttons-container d-flex justify-content-center ">
        <button
          @click="acceptInvitation"
          class="btns-action accept text-capitalize"
        >
          {{ $t("button.accept") }}
        </button>

        <router-link class="btns-action reject text-capitalize" to="/profile">{{
          $t("button.reject")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "invitation",
  data() {
    return {
      freindName: ""
    };
  },

  beforeMount() {
    if (!this.auth()) {
      return this.$router.push({ name: "Register" });
    }
  },
  mounted() {
    this.freindName = this.$route.params.title;
  },
  methods: {
    acceptInvitation() {
      this.$store
        .dispatch("contacts/acceptUserInvitation", this.$route.params.id)
        .then(() => {
          this.$router.push({name: 'ProfileView'});
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$add-color: #11bfe3;
$cancel-color: #fb404b;
$black: #000;
$white: #fff;
$transition: 0.3s all ease-in-out;
.invitation {
  min-height: 100%;
  max-height: calc(100vh - 75px);
  // position: relative;
  .invite {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 5px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
    // height: 300px;
    width: 500px;
    padding: 50px 20px;
    border-radius: 10px;
    .h6 {
      color: #707070;
      font-weight: 500;
      margin-bottom: 40px;
    }
    .invite-message {
      color: #707070;
      line-height: 1.7;
      .freind-name {
        color: $add-color;
      }
    }
    .buttons-container {
      gap: 30px;
      margin-top: 2.5rem;
      .btns-action {
        transition: $transition;
        width: 130px;

        outline: none;
        padding: 8px;
        border-radius: 5px;
        color: $white;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
        &.accept {
          background-color: $add-color;
          border: 1px solid $add-color;
        }
        &.reject {
          background-color: $cancel-color;
          border: 1px solid $cancel-color;
          text-decoration: none;
        }
      }
    }
  }
}

.logoo {
  width: 100px;
  height: 70px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 5px;
}
</style>
